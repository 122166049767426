import "./autoreg.js"

$(document).ready(function () {
  // mobile burger menu

  $(".burger").on("click", function () {
    $(this).toggleClass("burger_active")
    $(".header__navigation").slideToggle()
    $(".header__buttons").removeClass("active")
  })

  $(".mob-menu-toggle").on("click", function () {
    $(this).toggleClass("menu_active")
    $(".header__navigation-menu--mobile").slideToggle()
  })

  $(window).on("click", function (event) {
    if ($(event.target).hasClass("header__btn-mob-icon")) {
      $(".header__buttons").toggleClass("active")
      $(".burger").removeClass("burger_active")
      if ($(window).innerWidth() < 768) {
        $(".header__navigation").slideUp("fast")
      }
    }
  })

  $("li").has("ul").append('<span class="menu-down"></span>'),
    window.matchMedia("(max-width: 768px)").matches &&
      $(".menu-down").on("click", function () {
        $(this).parent().find($(".sub-menu")).slideToggle("fast")
      })

  $(".comment-form-author label").text("Author")
  //comments avatar
  $(".comment").each(function () {
    const author = $(this).find(".author").text(),
      name = author.split(" ")
    let result = ""
    for (let x = 0; x < name.length; x++) {
      if (x == 2) {
        break
      } else {
        result += name[x].charAt(0)
      }
    }
    $(this).find(".comment-avatar")[0].innerHTML = result
  })

  $("form").on("submit", function (e) {
    e.preventDefault()
  })

  $(".faq").on("click", function (e) {
    const $item = $(e.target).closest(".faq-item")

    if (!$item.length) return false

    if ($item.hasClass("active")) {
      $item.removeClass("active")
      $item.find(".faq-item__body").slideUp()
      return false
    }

    $(this)
      .find(".faq-item")
      .each(function () {
        $(this).removeClass("active")
        $(this).find(".faq-item__body").slideUp()
      })

    $item.toggleClass("active")
    $item.find(".faq-item__body").slideToggle()
  })
})
